import dayjs from 'dayjs'

export const toFriendlyDateTime = date => {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}

export const toFriendlyDate = (date, delimeter) => {
  return dayjs(date).format(`YYYY${delimeter ?? '-'}MM${delimeter ?? '-'}DD`)
}

export const toFriendlyTime = date => {
  return dayjs(date).format('HH:mm:ss')
}

export const toFriendlyTime12hr = date => {
  return dayjs(date).format('HH:mm:ss')
}

export const toFriendlyYear = date => {
  return dayjs(date).format('YYYY')
}

export const toFriendlyYearMonth = date => {
  return dayjs(date).format('YYYY-MM')
}

export const toFriendlyFirstDayMonth = date => {
  return dayjs(date).format('YYYY-MM-01')
}

export const toFriendlyFirstDayYear = date => {
  return dayjs(date).format('YYYY-01-01')
}

export const addDays = (date, duration, type) => {
  return dayjs(date).add(duration, type ?? 'days')
}

export const subtractDays = (date, duration, type) => {
  return dayjs(date).subtract(duration, type ?? 'days')
}

export const toFriendlyMonthName = (date, full) => {
  if (full) {
    return dayjs(date).format('MMMM YYYY')
  } else {
    return dayjs(date).format('MMM YYYY')
  }
}

export const countDate = (dateFrom, dateTo, type) => {
  return dayjs(dateTo).diff(dateFrom, type ?? 'days')
}
