import { createRouter, createWebHistory } from 'vue-router'
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerBall from 'quasar/src/components/spinner/QSpinnerBall.js';

const routes = [
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/PageNotFound')
  },

  {
    path: '/',
    name: 'index',
    component: () => import('../views/Landing')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/account/Login')
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import('../views/sales/Sales'),
    children: [
      {
        path: 'monthly',
        component: () => import('../views/sales/StoreMonthlySales')
      },
      {
        path: 'daily',
        component: () => import('../views/sales/StoreDailySales')
      },
      {
        path: 'hourly',
        component: () => import('../views/sales/StoreHourlySales')
      },
      {
        path: 'transaction-list',
        component: () => import('../views/sales/StoreTransactionList')
      },
      {
        path: 'transaction-details',
        component: () => import('../views/sales/StoreTransactionDetails')
      },
      {
        path: 'vat-sales',
        component: () => import('../views/sales/StoreVatSalesReport')
      },
      {
        path: 'by-payment-type',
        component: () => import('../views/sales/StoreSalesByPaymentType')
      },
      {
        path: 'returns',
        component: () => import('../views/sales/StoreSalesReturns')
      },
      {
        path: 'by-stores-sales',
        component: () => import('../views/sales/StoreByStoreSales')
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product/Product'),
    children: [
      {
        path: 'analysis',
        component: () => import('../views/product/ProductAnalysis')
      },
      {
        path: 'price-worksheet',
        component: () => import('../views/product/ProductPriceWorksheet')
      },
      {
        path: 'by-category',
        component: () => import('../views/product/ProductByCategoryReport')
      },
      {
        path: 'by-brand',
        component: () => import('../views/product/ProductByBrandReport')
      },
      {
        path: 'by-vendor',
        component: () => import('../views/product/ProductByVendorReport')
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('../views/inventory/Inventory'),
    children: [
      {
        path: 'valuation',
        component: () => import('../views/inventory/InventoryValuation')
      },
      {
        path: 'by-sku-movement',
        component: () => import('../views/inventory/InventorySKUMovement')
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('../views/customer/Customer'),
    children: [
      {
        path: 'customer-segmentation',
        component: () => import('../views/customer/CustomerSegmentation')
      },
      {
        path: 'sms-blast',
        component: () => import('../views/customer/CustomerSmsBlast')
      },
      {
        path: 'email-blast',
        component: () => import('../views/customer/CustomerEmailBlast')
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('../views/maintenance/Maintenance'),
    children: [
      {
        path: 'change-password',
        component: () =>
          import('../views/maintenance/ChangePasswordMaintenance')
      },
      {
        path: 'users',
        component: () => import('../views/maintenance/UserMaintenance')
      }
    ],
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token')

  if (to.name) {
    Loading.show({
      spinner: QSpinnerBall
    })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      next()
    } else {
      next('/login')
    }
  } else {
    const excludeAuth = ['index', 'login']

    if (isAuthenticated && excludeAuth.includes(to.name)) {
      next('/dashboard')
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  Loading.hide()
})

export default router
