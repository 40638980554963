import { reactive, readonly } from 'vue'

const state = reactive({
  isAuthenticated: localStorage.getItem('token'),
  profile: null,
  menus: []
})

const methods = {
  updateUserProfile(data) {
    state.profile = data
  },
  updateMenus(data) {
    state.menus = data
  }
}

const getters = {}

export default {
  state: readonly(state),
  methods,
  getters
}
