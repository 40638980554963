export const toCurrency = number => {
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
  }).format(number)
}

export const toNumber = number => {
  return Intl.NumberFormat('en-US').format(number)
}

export const capitalizeEachWord = data => {
  const arr = data.split(' ')

  arr.forEach((item, index) => {
    arr[index] = arr[index].charAt(0).toUpperCase() + arr[index].slice(1)
  })

  return arr.join(' ')
}

export const toFixedNumber = num => {
  return parseFloat(num?.toFixed(2))
}
