export const required = val => {
  return (val && val !== '') || 'This field is required'
}

export const requiredArray = val => {
  return (val && val.length > 0) || 'This field is required'
}

export const requiredFile = val => {
  return (val && (val.length > 0 || val.size)) || 'This field is required'
}

export const requiredLength = (val, min, max) => {
  return (
    (val && val !== '' && val.length >= min && val.length <= max) ||
    `Length should be between ${min} and ${max} characters`
  )
}

export const verifyPassword = (val, ref) => {
  return val === ref || 'Passwords don’t match'
}
