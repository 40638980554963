<template>
  <q-btn
    :class="buttonClass"
    :type="type"
    :color="variant"
    :size="size"
    :rounded="rounded"
    :outline="outline"
    :loading="loading"
    :disable="disabled"
    unelevated
  >
    <slot name="icon" />
    <div
      class="q-px-sm text-body2 text-weight-bold text-capitalize"
      v-if="label"
    >
      {{ label }}
    </div>
    <slot />
  </q-btn>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'Button',
  inheritAttrs: true,
  props: {
    type: {
      type: String,
      default: 'button'
    },
    label: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    outline: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const buttonClass = computed(() => {
      return [
        'text-weight-bold',
        {
          'full-width': props.fullWidth
        }
      ]
    })

    const updateValue = value => {
      emit('input', value)
    }

    return {
      buttonClass,
      updateValue
    }
  }
}
</script>
