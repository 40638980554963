import { ref } from 'vue'
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
  'token'
)}`

const fetchData = () => {
  const data = ref(null)
  const error = ref(null)
  const loading = ref(false)

  const post = async (url, payload) => {
    loading.value = true

    await axios
      .post(url, payload)
      .then(response => {
        data.value = response.data
        error.value = null
      })
      .catch(err => {
        data.value = null
        error.value = err?.response?.data ?? err
      })
      .then(() => {
        loading.value = false
      })
  }

  const get = async (url, payload) => {
    loading.value = true

    await axios
      .get(url, payload)
      .then(response => {
        data.value = response.data
        error.value = null
      })
      .catch(err => {
        data.value = null
        error.value = err?.response?.data ?? err
      })
      .then(() => {
        loading.value = false
      })
  }

  const put = async (url, payload) => {
    loading.value = true

    await axios
      .put(url, payload)
      .then(response => {
        data.value = response.data
        error.value = null
      })
      .catch(err => {
        data.value = null
        error.value = err?.response?.data ?? err
      })
      .then(() => {
        loading.value = false
      })
  }

  const remove = async (url, payload) => {
    loading.value = true

    await axios
      .delete(url, payload)
      .then(response => {
        data.value = response.data
        error.value = null
      })
      .catch(err => {
        data.value = null
        error.value = err?.response?.data ?? err
      })
      .then(() => {
        loading.value = false
      })
  }

  const upload = async (url, payload) => {
    const formData = new FormData()

    formData.append('file', payload)
    loading.value = true

    await axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        data.value = response.data
        error.value = null
      })
      .catch(err => {
        data.value = null
        error.value = err?.response?.data ?? err
      })
      .then(() => {
        loading.value = false
      })
  }

  return { data, error, loading, post, get, put, remove, upload }
}

export default fetchData
