<template>
  <q-header bordered>
    <q-toolbar>
      <q-btn flat @click="toggleSidebar" round dense icon="menu" />
      <q-space />

      <div v-if="profile && defaultStore">
        <q-btn-dropdown class="text-capitalize withoutIcon" flat stretch>
          <template v-slot:label>
            <div class="row items-start no-wrap">
              <div class="text-xs text-left">
                <q-icon left name="home" />
                <Badge :text="defaultStore" />
              </div>
            </div>
          </template>
        </q-btn-dropdown>
      </div>

      <q-btn-dropdown
        stretch
        flat
        :label="profile ? `${profile.first_name} ${profile.last_name}` : ''"
        icon="person"
        class="text-capitalize"
      >
        <q-list class="text-primary q-my-sm">
          <q-item
            v-for="list in navMenuLists"
            :key="list.name"
            clickable
            dense
            v-close-popup
            router
            :to="list.url"
          >
            <q-avatar :icon="list.icon" size="lg" />
            <q-item-section>
              <q-item-label class="q-mr-md">{{ list.name }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator inset spaced />
          <q-item clickable dense v-close-popup @click="onLogout">
            <q-avatar icon="logout" size="lg" />
            <q-item-section>
              <q-item-label>Logout</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-toolbar>
  </q-header>

  <Sidebar :sidebarOpen="sidebar" />
</template>

<script>
import { ref, computed, inject, onBeforeMount, watch } from 'vue'
import { useRouter } from 'vue-router'

import Sidebar from '@/components/Sidebar'
import Badge from '@/components/Badge'

export default {
  components: { Sidebar, Badge },
  setup() {
    const router = useRouter()
    const state = inject('store')?.state
    const sidebar = ref(false)
    const profile = ref(null)
    const navMenuLists = ref([])
    const defaultMenuLists = ref([
      {
        name: 'Change Password',
        icon: 'key',
        url: '/maintenance/change-password'
      }
    ])

    const defaultStore = computed(() => {
      return profile?.value?.account_name
    })

    const toggleSidebar = () => {
      sidebar.value = !sidebar.value
    }

    const onLogout = () => {
      const route = router.resolve({
        name: 'login'
      })

      localStorage.removeItem('token')
      window.location.assign(route.href)
    }

    watch(
      () => state?.menus,
      () => {
        navMenuLists.value = state?.menus
          ?.filter(item => {
            if (item.menu_tag === 'N') {
              return true
            }
            return false
          })
          .map(item => {
            return {
              name: item?.name,
              icon: item?.icon ?? 'settings',
              url: `/${item?.url}`
            }
          })

        defaultMenuLists.value.forEach(item => navMenuLists.value.unshift(item))
      }
    )

    onBeforeMount(() => {
      if (state?.profile) {
        profile.value = state.profile
      }
    })

    return {
      sidebar,
      navMenuLists,
      profile,
      defaultStore,
      toggleSidebar,
      onLogout
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.withoutIcon) {
  .q-btn-dropdown__arrow {
    @apply hidden;
  }
}
</style>
