import exportFile from 'quasar/src/utils/export-file.js';
import XLSX from 'xlsx'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'

import { Toast } from '@/tools'

export const wrapToCSV = (val, formatFn) => {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted =
    formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')

  return formatted
}

export const exportToCSV = (header, data, fileName) => {
  const { showToast } = Toast()

  const content = [header.map(col => wrapToCSV(col.label))]
    .concat(
      data.map(row =>
        header
          .map(col => {
            return wrapToCSV(
              typeof col.field === 'function'
                ? col.field(row).toString().replace(/,/g, '')
                : row[col.field === void 0 ? col.name : col.field]
                    .toString()
                    .replace(/,/g, ''),
              col.format
            )
          })
          .join(',')
      )
    )
    .join('\r\n')

  const status = exportFile(`${fileName}.csv`, content, 'text/csv')

  if (status !== true) {
    showToast('Browser denied file download...', 'warning')
  }
}

export const exportToPDF = (header, data, fileName, id, graph) => {
  const doc = new jsPDF()
  let canvasImg = ''

  if (graph) {
    //Convert svg to png
    const svgString = new XMLSerializer().serializeToString(
      document.querySelector(`#${id}`)
    )

    const canvas = document.createElement('canvas')
    canvas.width = 656
    canvas.height = 382
    const ctx = canvas.getContext('2d')
    const DOMURL = self.URL || self.webkitURL || self
    const img = new Image()
    const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
    const url = DOMURL.createObjectURL(svg)
    img.onload = function () {
      ctx.drawImage(img, 0, 0)
      canvasImg = canvas.toDataURL('image/png', '1.0')
    }
    img.src = url
  }

  //Generate Table
  const headers = header.map(item => ({
    header: item.label,
    dataKey: item.name
  }))

  setTimeout(() => {
    doc.addImage(canvasImg, 'PNG', 10, 10)

    doc.autoTable({
      startY: graph ? 120 : 15,
      columns: headers,
      body: data
    })

    //Page number
    const pages = doc.internal.getNumberOfPages()
    const pageWidth = doc.internal.pageSize.width
    const pageHeight = doc.internal.pageSize.height
    doc.setFontSize(10)

    for (let i = 1; i < pages + 1; i++) {
      let horizontalPos = pageWidth / 2
      let verticalPos = pageHeight - 10
      doc.setPage(i)
      doc.text(`${i} of ${pages}`, horizontalPos, verticalPos, {
        align: 'center'
      })
    }

    doc.save(`${fileName}.pdf`)
  }, 1000)
}

export const exportToExcel = (header, data, fileName, sheetName) => {
  const content = [header.map(col => wrapToCSV(col.label))]
    .concat(
      data.map(row =>
        header
          .map(col => {
            return wrapToCSV(
              typeof col.field === 'function'
                ? col.field(row).toString().replace(/,/g, '')
                : row[col.field === void 0 ? col.name : col.field]
                    .toString()
                    .replace(/,/g, ''),
              col.format
            )
          })
          .join(',')
      )
    )
    .join('\r\n')
    .split('\n')
    .map(item => item.split(','))

  const worksheet = XLSX.utils.aoa_to_sheet(content, { origin: 'A1' })
  const workbook = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName || 'Sheet 1')

  return XLSX.writeFile(workbook, `${fileName}.xlsx`)
}
