<template>
  <div v-if="inner">
    <q-inner-loading :showing="loading">
      <q-spinner size="4rem" color="primary" />
    </q-inner-loading>
  </div>
  <div v-else>
    <div v-if="loading" class="row items-center justify-center">
      <q-spinner-dots color="primary" size="4rem" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    inner: {
      type: Boolean,
      default: false
    }
  }
}
</script>
