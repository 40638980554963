<template>
  <div class="PageCenter column items-center justify-center gap-2">
    <div class="row items-center justify-center gap-4">
      <h4>404</h4>
      <q-separator vertical color="black" />

      <h6>Page not found</h6>
    </div>

    <p>Sorry, the page you requested could not be found.</p>
    <p>Please go back or contact us.</p>

    <br />
    <Button
      variant="primary"
      label="Go Back"
      outline
      @click="this.$router.back()"
    />
  </div>
</template>

<script>
import Button from '@/components/Button'

export default {
  name: 'PageNotFound',
  components: { Button }
}
</script>

<style lang="scss" scoped>
.PageCenter {
  height: calc(100vh - 150px);
}
</style>
