<template>
  <q-dialog v-model="showModal">
    <q-card class="modalContainer" :style="{ width }" v-if="simple">
      <q-card-section> </q-card-section>

      <q-card-section class="q-pt-none">
        <Loader :loading="loading" />
        <slot v-if="!loading" />
      </q-card-section>

      <q-card-actions align="right">
        <q-card-actions class="q-ma-sm row justify-end">
          <Button
            variant="primary"
            :label="cancelLabel"
            outline
            @click="close"
          />
          <q-space v-if="!closeOnly" />
          <Button
            variant="primary"
            :label="okLabel"
            :loading="loadingSubmit"
            @click="submit"
            v-if="!closeOnly"
          />
        </q-card-actions>
      </q-card-actions>
    </q-card>

    <q-card class="modalContainer" :style="{ width }" v-else>
      <q-card-section class="row items-center bg-blue-10 text-white">
        <div class="text-h6">{{ header }}</div>
        <q-space />
        <q-btn icon="close" flat round dense @click="close" />
      </q-card-section>

      <q-card-section style="max-height: 70vh" class="scroll">
        <Loader :loading="loading" />
        <slot v-if="!loading" />
      </q-card-section>

      <q-separator />

      <q-card-actions class="q-ma-sm row justify-end">
        <Button variant="primary" :label="cancelLabel" outline @click="close" />
        <q-space v-if="!closeOnly" />
        <Button
          variant="primary"
          :label="okLabel"
          :loading="loadingSubmit"
          @click="submit"
          v-if="!closeOnly"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed } from 'vue'

import Button from '@/components/Button'
import Loader from '@/components/Loader'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: ''
    },
    okLabel: {
      type: String,
      default: 'Okay'
    },
    cancelLabel: {
      type: String,
      default: 'Cancel'
    },
    width: {
      type: String,
      default: ''
    },
    closeOnly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingSubmit: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  components: { Button, Loader },
  setup(props, { emit }) {
    const showModal = computed({
      get() {
        return props.show
      },
      set(value) {
        if (!value) {
          emit('close')
        }
      }
    })

    const close = () => {
      emit('close')
    }

    const submit = () => {
      emit('submit')
    }

    return { showModal, close, submit }
  }
}
</script>

<style lang="scss" scoped>
.modalContainer {
  max-width: 80vw;
}
</style>
