<template>
  <div class="PageCenter column items-center justify-center">
    <div v-if="loading">
      <q-spinner-dots color="primary" size="4rem" />
    </div>
    <img src="@/assets/images/logo_retina.png" width="250" />
  </div>
</template>

<script>
export default {
  name: 'Landing',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.PageCenter {
  height: calc(100vh - 100px);
}
</style>
