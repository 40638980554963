import Notify from 'quasar/src/plugins/Notify.js';

const toast = () => {
  const showToast = (message, type, position, timeout) => {
    let icon = ''
    let color = ''

    switch (type) {
      case 'success': {
        icon = 'check_circle'
        color = 'success'
        break
      }
      case 'warning': {
        icon = 'warning'
        color = 'warning'
        break
      }
      case 'danger': {
        icon = 'error'
        color = 'danger'
        break
      }
      case 'info': {
        icon = 'info'
        color = 'info'
        break
      }
      default: {
        color = ''
      }
    }

    return Notify.create({
      icon: icon,
      color: color,
      message: message,
      position: position ?? 'top',
      textColor: type === 'info' ? 'black' : 'white',
      timeout: timeout ?? 2500,
      html: true,
      actions: [
        {
          icon: 'close',
          color: type === 'info' ? '' : 'white'
        }
      ]
    })
  }

  return { showToast }
}

export default toast
