export const excludedPaths = ['/dashboard', '/maintenance/change-password']

export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const times = [
  {
    name: '12AM',
    value: 0
  },
  {
    name: '01AM',
    value: 1
  },
  {
    name: '02AM',
    value: 2
  },
  {
    name: '03AM',
    value: 3
  },
  {
    name: '04AM',
    value: 4
  },
  {
    name: '05AM',
    value: 5
  },
  {
    name: '06AM',
    value: 6
  },
  {
    name: '07AM',
    value: 7
  },
  {
    name: '08AM',
    value: 8
  },
  {
    name: '09AM',
    value: 9
  },
  {
    name: '10AM',
    value: 10
  },
  {
    name: '11AM',
    value: 11
  },
  {
    name: '12PM',
    value: 12
  },
  {
    name: '01PM',
    value: 13
  },
  {
    name: '02PM',
    value: 14
  },
  {
    name: '03PM',
    value: 15
  },
  {
    name: '04PM',
    value: 16
  },
  {
    name: '05PM',
    value: 17
  },
  {
    name: '06PM',
    value: 18
  },
  {
    name: '07PM',
    value: 19
  },
  {
    name: '08PM',
    value: 20
  },
  {
    name: '09PM',
    value: 21
  },
  {
    name: '10PM',
    value: 22
  },
  {
    name: '11PM',
    value: 23
  }
]
